import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import SideBar from "./Components/sidebar/sideBar";
import Navbar from "./Components/navbar/Navbar";
import Homepage from "./pages/homepage";
import CreatePatient from "./pages/create-patient/createPatient";
import CreateUser from "./pages/createUser/createUser";
import Override from "./pages/override-form/override";
import Login from "./pages/login/login";
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-toastify/dist/ReactToastify.css';
import "./App.css";
import { MdMenu, MdClose } from "react-icons/md";
import { ToastContainer, toast } from 'react-toastify';
// import axios from"axios";
import useAuthInterceptor from "./utilities/authInstance";

// function App() {
//   const [isSidebarVisible, setIsSidebarVisible] = useState(true);
//   const [isAuthenticated, setIsAuthenticated] = useState(false);
//   const [isAuthChecked, setIsAuthChecked] = useState(false);

//   useEffect(() => {
//     const authStatus = localStorage.getItem("authenticated");
//     if (authStatus === "true") {
//       setIsAuthenticated(true);
//     }else{
//       if(isAuthChecked) {
//         toast.error("Session expired. Please log in again.");
//       }
//       setIsAuthChecked(false);
//     }
//     setIsAuthChecked(true);

//     const axiosInterceptor = axios.interceptors.response.use(
//       response => response,
//       error => {
//         if (error.response?.status === 401) {
//           toast.error("Session expired. Please log in again.");
//           localStorage.removeItem("authenticated");
//           localStorage.removeItem("access_token");
//           localStorage.removeItem("userRoles");
//           setIsAuthenticated(false);
//           setTimeout(() => {
//             window.location.href = "/login";
//           }, 3000); // 2 seconds delay before redirecting to login
//         }
//         return Promise.reject(error);
//       }
//     );

//     return() => {
//       axios.interceptors.response.eject(axiosInterceptor);
//     };
//   }, [isAuthChecked]);

//   const toggleSidebar = () => {
//     setIsSidebarVisible(!isSidebarVisible);
//   };

//   const userRoles = JSON.parse(localStorage.getItem("userRoles")) || [];

//   if (!isAuthChecked) {
//     return null; // Or a loading spinner, if preferred
//   }

  function App() {
    const [isSidebarVisible, setIsSidebarVisible] = useState(true);
    const { isAuthenticated, setIsAuthenticated, isAuthChecked } = useAuthInterceptor(); // Use the hook
    const toggleSidebar = () => {
      setIsSidebarVisible(!isSidebarVisible);
    };
  
    const userRoles = JSON.parse(localStorage.getItem("userRoles")) || [];
  
    if (!isAuthChecked) {
      return null; // Or a loading spinner, if preferred
    }

  return (
    <Router>
      {isAuthenticated && <Navbar setIsAuthenticated={setIsAuthenticated} />}
      <Container fluid>
        {isAuthenticated && (
          <Button
            variant="primary"
            onClick={toggleSidebar}
            className="sidebar-toggle-btn"
          >
            {isSidebarVisible ? <MdClose className="icon" /> : <MdMenu className="icon" />}
          </Button>
        )}
        <Row>
          {isAuthenticated && (
            <Col
              sm={12}
              md={isSidebarVisible ? 3 : 0}
              className={`p-0 sidebar-container ${isSidebarVisible ? "" : "hidden"}`}
            >
              <SideBar isVisible={isSidebarVisible} toggleSidebar={toggleSidebar} />
            </Col>
          )}
          <Col sm={12} md={isAuthenticated && isSidebarVisible ? 9 : 12} className="p-0">
            <Routes>
              {!isAuthenticated ? (
                <>
                  <Route path="/login" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
                  <Route path="*" element={<Navigate to="/login" replace />} />
                </>
              ) : (
                <>
                  <Route path="/" element={<Homepage />} />
                  <Route path="/create-patient" element={<CreatePatient />} />
                  <Route path="/override-form" element={<Override />} />
                  <Route path="/login" element={<Navigate to="/" replace />} />
                  {userRoles.includes("Admin") && (
                    <Route path="/createUser" element={<CreateUser />} />
                  )}
                </>
              )}
            </Routes>
          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </Router>
  );
}

export default App;
