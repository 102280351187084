import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import baseUrl from "../../utilities/baseUrl";

const LoginFunction = (setIsAuthenticated) => {
  const [loginName, setLoginName] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${baseUrl}/api/Auth/Login`, {
        loginName,
        loginPassword,
      });

      if (response.data.accessToken) {
        localStorage.setItem("authenticated", "true");
        localStorage.setItem("token", response.data.accessToken);
        localStorage.setItem("userRoles", JSON.stringify(response.data.userRoles)); // Store roles
        setIsAuthenticated(true);
        navigate("/");
      } else {
        setError("Invalid credentials");
      }
    } catch (err) {
      setError("Incorrect username or password!");
    }
  };

  return {
    loginName,
    setLoginName,
    loginPassword,
    setLoginPassword,
    handleLogin,
    error,
  };
};

export default LoginFunction;
