import axios from "axios";
import fileDownload from "js-file-download";
import baseUrl from "../../utilities/baseUrl";

const downloadFile = async () => {
  try {
    const token = localStorage.getItem("token");

    const response = await axios.get(`${baseUrl}/api/PatientDiagnosis`, {
      responseType: "blob", // Important for file downloads
      headers: {
        Authorization: `Bearer ${token}`, // Correctly include the Bearer token here
      }, // Include the Bearer token
      
    });
    fileDownload(response.data, "DRGTestResults.xlsx");
  } catch (error) {
    console.error("Error downloading the file", error);
  }
};

export default downloadFile;
