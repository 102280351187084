import { useState } from "react";
import axios from "axios";
import baseUrl from "../../utilities/baseUrl";
import check from "./validationInput";

const OverrideFunctions = () => {
  const [loading, setLoading] = useState(false);
  const [updateType, setUpdateType] = useState(null);
  const [file, setFile] = useState(null); // State for the file
  const [error, setError] = useState(null); // State for managing API error
  const [updateTypeError, setUpdateTypeError] = useState(null); // State for managing DRG type selection error
  const [fileError, setFileError] = useState(null); // State for managing file selection error
  const [fileName, setFileName] = useState(""); // State to hold the file name
  const [uploading, setUploading] = useState(false); // State to handle loading
  const [uploadProgress, setUploadProgress] = useState(0); // State for upload progress
  const [successMessage, setSuccessMessage] = useState(null); // State for success message



  const handleUpdateTypeChange = (value) => {
    setUpdateType(value);
    setUpdateTypeError(null); 
  };

  const handleFileChange = async (e) => {
    setUpdateTypeError(null);
    const file = e.target.files[0];
    setFile(file);
    setFileName(file ? file.name : "No file chosen");
    setUploading(true); // Show loading when file is selected

    const formData = new FormData();
    formData.append("file", file);

    try {
      // Simulate file upload with progress tracking
      await axios.post(`/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percent);
        },
      });
      setUploadProgress(100); // Set to 100% when done
    } catch (error) {
      console.error(error);
    } finally {
      setUploading(false); // Hide loading after upload
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (updateType === null) {
      check(updateType, setUpdateTypeError, "Please select an update type.");
      return;
    } else {
      setUpdateTypeError(null);
    }

    if (!file) {
      setFileError("Please select a file.");
      return;
    } else {
      setFileError(null);
    }

    const formData = new FormData();
    formData.append("updateType", updateType);
    formData.append("file", file);

    try {
      const token = localStorage.getItem("token");
      setLoading(true);

      const response = await axios.post(`${baseUrl}/api/Override`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      const data = response.data;
      if (data.length > 0) {
        setSuccessMessage("File uploaded successfully!"); // Set success message
        setTimeout(() => setSuccessMessage(null), 5000);
      } else {
        console.warn("Error!!");
      }
      setError(null);
    } catch (error) {
      console.error("Error submitting form:", error);
      setError(error.response?.data || "An error occurred."); // Set error message in state
    } finally {
      setLoading(false); // Reset overall loading state
    }
  };

  const clearError = () => {
    setError(null); // Clear error when dismissed by user
  };

  const clearSuccessMessage = () => {
    setSuccessMessage(null); // Clear success message when dismissed by user
  };

  return {
    loading,
    error,
    successMessage, // Return success message
    clearError,
    clearSuccessMessage, // Expose clearSuccessMessage function
    setUpdateType,
    handleSubmit,
    updateTypeError,
    setFile, // Expose setFile function
    fileError, // Expose fileError state
    fileName,
    uploading,
    uploadProgress,
    handleFileChange,
    handleUpdateTypeChange
  };
};

export default OverrideFunctions;
